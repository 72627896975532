<template>
  <div class="container" style="margin:120px auto">
    <div class="row">
      <div class="col">
        <div class="d-flex my-3">
          <h3 class="title pr-3">{{ $t('Home.Daily_English') }}
            <span v-for="item in dailySentencesList">
              <button type="button" :class="{'active': item.id.includes(dailySentencesType) }" @click="selectType(item.id)" class="btn btn-sm btn-outline-danger ml-1">{{ item.name }}</button>
            </span>
          </h3>
          <div class="line"></div>
        </div>
        <keep-alive>
          <DailySentence :inputAudioData="audioData"/>
        </keep-alive>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-8">
        <div class="d-flex my-3">
          <h3 class="title pr-3">{{ $t('Home.World_News') }}</h3>
          <div class="line" style="width:95%"></div>
        </div>
        <div>
          <DailyNews class="pr-3"  v-if="dailyNews" :inputNews="dailyNews[0]" :view_id="dailyNews[0].sections[0].data.view_id"/>
          <div v-else>
            <h4>
              請先完成學前測驗
            </h4>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="d-flex my-3">
          <h3 class="title pr-3">{{ $t('Home.Lets_play_in_English') }}</h3>
          <div class="line"></div>
        </div>
        <div v-if="dailyEnglish">
          <DailyEnglish :inputData="dailyEnglish"/>
        </div>
        <div v-else>
          <h4>
            請先完成學前測驗
          </h4>
        </div>
      </div>
    </div> -->
    <div class="row" v-if="recordLesson.length>0">
      <div class="col-md-12">
        <div class="d-flex my-3">
          <h3 class="title pr-3">{{ $t('Home.Learning_History') }}</h3>
          <div class="line"></div>
        </div>
        <div class="row my-3">
          <div class="col-lg-3 col-md-6 col-12" v-for="(item, index) in recordLesson.slice(0,4)" :key="index">
            <div @click="goPage(item.id)">
              <div class="picture image-box pointer" :data-level="item.cefr" >
                <img class="cover" :src="item.cover" alt="">
              </div>
              <div class="ml-2">
                <p v-html="item.name" class="m-0" style="font-size:1.1rem">
                </p>
                <p v-html="item.name_en" class="m-0" style="font-size:.8rem">
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="recentLessons.length>0">
      <div class="col">
        <div class="d-flex mt-3">
          <h3 class="title pr-3">{{ $t('Home.Latest_Courses') }}</h3>
          <div class="line"></div>
        </div>
        <div style="text-align:right;width:100%">
          <span class="text-danger pointer" @click="goList()">
            More
          </span>
        </div>
        <div class="row my-3">
          <div class="col-lg-3 col-md-6 col-12" v-for="(item, index) in recentLessons.slice(0,4)" :key="index">
            <div @click="goPage(item.id)">
              <div class="picture image-box pointer" :data-level="item.cefr" >
                <img class="cover" :src="item.cover" alt="">
              </div>
              <div class="ml-2">
                <p v-html="item.name" class="m-0" style="font-size:1.1rem">
                </p>
                <p v-html="item.name_en" class="m-0" style="font-size:.8rem">
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex my-3">
          <h3 class="title pr-3">{{ $t('Home.Recommend') }}</h3>
          <div class="line"></div>
        </div>
          <div class="row my-3">
            <div class="col-lg-6 col-12" v-for="(item, index) in recommendLesson.slice(0,4)" :key="index">
            <div class="d-sm-flex my-2">
              <div style="text-align:center;" @click="goCourse(item.id)">
                <img class="m-auto pointer" style="max-width:265px" :src="item.cover" alt="">
              </div>
              <div style="border:solid 1px #ccc;" class="p-2 m-4 m-sm-0">
                <h4 class="m-0">
                  {{item.name}}
                </h4>
                <p class="m-0" v-html="forceToJap(item.name_en)" style="font-size:1rem">
                  <!-- {{item.name_en}} -->
                </p>
                <span v-for="(el, index) in item.cefr.split(',')" :key="index" class="badge badge-danger mx-1 p-1">{{el}}</span>
                <div class="mt-3" style="height:75%; overflow: auto;">
                  <p style="height:150px">
                    {{item.description}}
                  </p>
                  <div class="p-2" style="text-align:right;">
                    <button type="button" @click="goCourse(item.id)" class="btn btn-dark">{{ $t('Home.Go_to_course') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex my-3">
          <h3 class="title pr-3">{{ $t('Home.Ability_comparison') }}</h3>
          <div class="line"></div>
        </div>
        <div class="row my-3 align-items-stretch" style="postition: relative">
          <div class="col-md-3">
            <div style="border:solid 1px #ccc;height:100%">
              <div class="JLPT-label">
                <h4 class="p-2" style="color:#fff">JLPT簡介</h4>
              </div>
              <div class="p-2">
                <div>
                  <p style="font-size: .9rem">
                    日本語能力試驗(JLPT—Japanese Language Proficiency Test)是從1984年由日本國際交流基金會與日本國際教育支援協會共同舉辦的。這是為了母語非日語的學習者所舉辦的日語能力檢定測驗，通稱為日檢。每年在7 月及12 月各舉辦一次，除了在日本的外國人能參加測驗之外，在海外的日語學習者也可以查詢自己的國家地區是否有參加共同測驗就近報名應考。根 據官網最新資料，2017 年全球報名測驗人數多達102 萬人，分別於81 個國家實施測驗，可說是世界最大規模之日語測驗。日語能力試驗的應試者來自各國，有些想測試自我日語能力，有些則是希望藉由通過測驗而順利畢業、求職、加薪晉升、海外生活等等。
                  </p>
                </div>
                <!-- <div style="text-align: right">
                  <a target="_blank" href="https://www.coe.int/en/web/">更多參考資料</a>
                </div> -->
              </div>
              <!-- <div style="text-align: right">
                <button type="button" @click="goExam()" class="btn btn-danger mx-2 mb-2">了解目前能力</button>
              </div> -->
            </div>
            <!-- <div style="text-align: left">
              <button type="button" class="btn btn-danger my-2">了解目前能力</button>
            </div> -->
          </div>
          <div class="col-md-9">
            <div class="cefr-pic">
              <img style="width:100%;" src="../assets/image/LLJP_JLPT_banner_bg.png" alt="">
              <!-- <img style="width:15%; position:absolute; bottom:16%; left:5%" src="../assets/image/LLPRO_CEFR_banner_woman.png" alt=""> -->
              <!-- <img style="width:20%; position:absolute; bottom:50%; right:0" src="../assets/image/LLPRO_CEFR_banner_man.png" alt=""> -->
              <div class="m-3 p-2 cefr-content" v-for="(item, index) in chats" :key="index">
                <div v-if="index === currentBar">
                  <div class="d-flex">
                    <div class="cefr-title mr-2 mb-3">
                      <span class="cefr-icon"> {{item.level}}</span>
                      <span class="mx-2">
                        <span>{{item.name}} {{item.subName}}</span>
                      </span>
                    </div>
                  </div>
                  <div class="mb-3">
                    <button type="button" @click="isAdvice=false" :class="{'active': isAdvice === false}" class="btn btn-sm btn-outline-secondary">能力說明</button>
                    <button type="button" @click="isAdvice=true" :class="{'active': isAdvice === true}" class=" btn btn-sm btn-outline-secondary mx-2">學習建議</button>
                  </div>
                  <div v-if="!isAdvice">
                    {{item.depiction}}
                  </div>
                  <div v-else>
                    {{item.advice}}
                  </div>
                  </div>
                </div>
              <div class="d-flex chart">
                <div class="bar-layout" v-for="(item, index) in chats" :key="index">
                  <div class="mx-auto bar pointer d-flex justify-content-center align-items-end" @click="currentBar = index" :style="{height: item.bar}" :class="{'active': currentBar === index}">
                    <div class="bar-text">
                      {{item.level}}
                    </div>
                  </div>
                  <div class="bar-name">{{item.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import Player from 'live-player/dist/Player'
import DailyNews from '@/components/DailyNews.vue'
import DailySentence from '@/components/DailySentence.vue'
// import DailyEnglish from '@/components/DailyEnglish.vue'
import apiLibrary from '../store/library/api'
import api from '../store/api'

export default {
  name: 'Home',
  components: {
    Player,
    DailyNews,
    DailySentence,
    // DailyEnglish
  },
  computed: {
    ...mapGetters([
      'authInfo',
    ])
  },
  watch: {
    dailySentencesType (val) {
      if (val) {
        this.getDailySentences()
      }
    }
  },
  data () {
    return {
      audioData: null,
      recentLessons: [],
      recommendLesson: [],
      recordLesson: [],
      dailyNews: null,
      dailyEnglish: null,
      channels: [],
      dailySentencesList: [],
      dailySentencesType: [],
      exam: null,
      isAdvice: false,
      chats: [
        {
          name: '基礎',
          level: 'N5',
          bar: '8%',
          depiction: '對於基礎的日語有一定程度的理解。為語彙量、文法量的萌芽階段。可閱讀簡單的指示、能以簡單的名詞句、形容詞句或動詞句進行會話。亦能聆聽理解句子中的關鍵。 ',
          advice: '此階段學員建議先從「平假名跟我學」、「片假名跟我學」開始學習。若已熟悉五十音，則建議開始學習「基礎日語必備課程」，認識日文裡必備的基礎句型、學習日常生活中常用的單字，以及簡單的招呼語。待學習完「基礎日語必備課程」之後，請繼續學習「初級日語必備課程」。循序漸進學習，如此便可奠定良好的日語基礎。另外建議搭配「必學日語基礎文法」，透過老師的真人解說，為您掌握文法關鍵、解惑學習文法時常見的混淆點。此外，「圖解實用日語百科」中有豐富的生活主題類別單字，能讓您迅速累積語彙量。'
        },
        {
          name: '初級',
          level: 'N4',
          bar: '16%',
          depiction: '能夠理解基礎的日語。雖無法應對所有日常生活情境，但已有基礎的語彙量及文法概念。可將基本日語能力應用於餐廳、問路、閱讀指示等基本日常用途。 ',
          advice: '此階段學員已有相當的基礎，建議開始學習「生活日語」類別中的課程。例如：「吃美食學日語」、「最新日語流行語」當中以輕鬆有趣的話題，帶領您學習課本上學不到的實用日語。「主題式情境會話」中則以真人會話影片讓您練習實用口語會話。此外，也建議學習「旅遊必備日語會話」，熟悉食衣住行常用的對話表現方式，讓您學習更得心應手。'
        },
        {
          name: '中級',
          level: 'N3',
          bar: '24%',
          depiction: '對於日常生活中的日語有一定程度的理解。日語能力已邁向進階，聽說讀寫能力逐漸進步，語彙量、文法觀念也迅速累積中。',
          advice: '此階段學員已具備閱讀進階文章的能力，因此建議可學習文化日語中的「日本經典故事選」、「日語慣用句達人就是你」、「日本文化面面觀」等課程。認識日本文化亦是日語學習中重要的一環。若能認識這些文化、歷史背景，相信您的日語會更有深度。此外，也建議學習「閱讀日語漫遊日本」、「帶日本朋友遊台灣」等長篇閱讀課程。藉由受人喜愛的旅遊主題，加深閱讀能力及語彙、文法能力。此外，也建議開始學習「掌握職場基本禮儀」、「練習職場用語入門」，學習職場實用日語，練習適切的日語表現。'
        },
        {
          name: '中高級',
          level: 'N2',
          bar: '32%',
          depiction: '能夠理解日常生活中的日語，並且對於廣泛的場合中所使用的日語有一定程度的理解。雖對於正式場合或專業領域中的日語理解偶有不足，但仍能應對日常生活中各情境。',
          advice: '此階段學員已有相當高的日語能力，若已學習完前述課程，建議可學習職場日語相關課程，例如「公司內部的溝通、應對」，使用更進階的日語應付職場上的溝通、應對進退。'
        },
        {
          name: '高級',
          level: 'N1',
          bar: '40%',
          depiction: '能夠理解在廣泛的場合中所使用的日語。日語能力達到純熟，可應用於例如職場商務、大型場合，甚至升學考試、求職面試中。',
          advice: '此階段學員已有卓越的日語能力，相信任何主題都能符合您的學習需求。建議您除了自己感到興趣的主題之外，可專攻「拜訪、接聽電話」、「撰寫電子郵件」等高級職場日語主題課程，並且實際應用在職場上，讓自己更熟悉進階日語閱讀及寫作。'
        }
      ],
      currentBar: 0
    }
  },
  mounted () {
    this.scrollTop()
    this.getExam()
    this.getDailySentencesList()
    this.getDailySentences()
    if (this.authInfo.user.forcePreTest) {
      if (this.authInfo.user.isFinishPreTest) {
        this.getNews()
        this.getEnglish()
      }
    } else {
      this.getNews()
      this.getEnglish()
    }
    this.getRecentLessons()
    this.getRecordLessons()
    this.getRecommendLessons()
  },
  methods: {
    async getDailySentencesList () {
      const { data } = await apiLibrary.get('/v1/daily-sentences/categories/livelearningpro?lang=ja')
      this.dailySentencesList = data
      // 預設type為第一顆按鈕
      this.dailySentencesType = data[0].id
    },
    async getDailySentences () {
      const { data } = await apiLibrary.get(`/v1/daily-sentences?lang=ja&category=${this.dailySentencesType}`)
      this.checkCollections([data.data[0]])
    },
    selectType (id) {
      this.dailySentencesType = id
    },
    async getNews() {
      const params = {
        page: 1,
        pageSize: 1,
        orderBy: 'desc',
        type: 'daily_news'
      }
      const { data } = await api.get('/contents/courses/', { params })
      this.dailyNews = data.data
    },
    async getEnglish() {
      const params = {
        page: 1,
        pageSize: 1,
        type: 'daily_english',
        orderBy: 'desc'
      }
      const { data } = await api.get('/contents/courses/', { params })
      this.dailyEnglish = data.data[0]
    },
    async getRecentLessons() {
      const { data } = await api.get('contents/courses/recent')
      this.recentLessons = data.data
    },
    async getRecordLessons() {
      const params = {
        page:1,
        pageSize:4
      }
      const { data } = await api.get('/contents/courses/history', params)
      this.recordLesson = data.data
    },
    async getRecommendLessons() {
      const { data } = await api.get('contents/courses/recommend')
      // let item = data.data[Math.floor(Math.random()*data.data.length)]
      this.recommendLesson = data.data
    },
    async checkCollections (el) {
      let str = ''
      let checkArray = []
      for (const item in el) {
        str += el[item].id + ','
      }
      try {
        const { data } = await api.get(`collections/daily_sentence/check?ids=${str}`)
        checkArray = data.data
      } catch (error) {
        console.error(error.response.data)
      }
      for (const item in el) {
        const idx = checkArray.indexOf((el[item].id))
        if (idx > -1) {
          el[item].collection = true
        } else {
          el[item].collection = false
        }
      }
      this.audioData = el
    },
    async getLessonId (id) {
      const { data } = await api.get(`/contents/courses/${id}`)
      if (data.status === 'Success') {
        this.$nextTick(() => {
          // this.getParents(data.data.category_id)
        })
        return data.data
      }
    },
    async getExam () {
      const { data } = await api.get('/contents/exams?page=1&pageSize=10&type=classify')
      this.exam = data.data[0]
    },
    goExam() {
      this.$router.push({ name: 'Exam-area' })
    },
    async goPage (id) {
        await this.getLessonId(id).then((val) => {
        this.$router.push({ name: 'CourseLesson', params: { lesson: val.id, courseId: val.sections[0].data.view_id||val.sections[0].id } })
      } )
    },
    async goList ()  {
      this.$router.push({ name: 'Search' })
    },
    async goCourse (id) {
      this.$router.push({ name: 'Course', params: { channel: id } })
    },
    async getLessonId (id) {
      const { data } = await api.get(`/contents/courses/${id}`)
      if (data.status === 'Success') {
        return data.data
      }
    },
    scrollTop () {
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    },
    forceToJap (sent, hidePinyin = false) {
      const split = sent.split(/{(.+?)}/)
      let newSent = ''
      for (const i in split) {
        if (split[i].indexOf('=') > -1) {
          if (!hidePinyin) {
            newSent += '<ruby>' + split[i].split(/=/)[0] + '<rt>' + split[i].split(/=/)[1] + '</rt></ruby>'
          } else {
            newSent += split[i].split(/=/)[0]
          }
        } else {
          newSent += split[i]
        }
      }
      return newSent
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/styles/color.scss";

  .container {
    text-align: left;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    color: $main-color;
    background-color: $white-color;
    z-index: 10;
  }
  .line {
    // width: 100%;
    width: 98%;
    position: absolute;
    // left: 100%;
    top: 32px;
    border-top: black 1px solid;
  }
  .picture .cover:hover {
    transform: scale(1.05)  translate(-50%,-50%);
    filter:opacity(.8);
  }
  .picture {
    position:relative;
    min-height: 150px;
    max-height: 150px;
    overflow: hidden;
    .cover {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition:all 0.2s ease;
    }
    .tag {
      width: 40px;
      position: absolute;
      padding: 10px
    }
  }
  .image-box::after {
    content: attr(data-level);
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 20%;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: $main-color;
    color:white;
  }
  .image-box::before {
    content: attr(data-time);
    padding: 1px;
    z-index: 2;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: .5rem;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
  }
  .JLPT-label {
    background: $sub-color;
    text-align: center;
  }
  .cefr-pic {
    position: relative;
    .cefr-content {
      .cefr-title {
        font-size: 1.2rem;
        font-weight: bold;
        color: $main-color;
        .cefr-icon {
          color: #fff;
          background-color: red;
          padding: 2px 8px;
          border-radius: 4px;

        }
      }
      .bg-danger {
        color: #fff;
      }
      border-radius: 20px;
      width: 70%;
      position: absolute;
      top: 0;
      z-index:50 !important;
    }
    .btn:hover,
    .btn:focus {
      outline: none;
      box-shadow: none !important;
    }
    .bar-layout {
      width: 100%;
      position: relative;
      .bar-name {
        font-weight: bold;
        position: absolute;
        bottom:10px;
        text-align:center;
        width:100%;
      }
      .bar {
        border-radius: 10px 10px 0 0;
        width:50%;
        background: #F3A064;
        position: absolute;
        bottom: 40px;
        right: 25%;
        transition: all .5s cubic-bezier(0.42, 0, 0.58, 1.0);
        .bar-text {
          color:#fff;
        }
      }
      .active {
        background: red;
      }
    }
  }
  .subtitle {
    margin: 2%;
    width: 96%;
    line-height: 3rem;
    font-size: 1.5rem;
    box-shadow: 4px 2px 2px 2px rgba(0, 0, 0, 0.2);
  }
  .pop-title {
    h4 {
      margin: 0;
      font-size: 1rem !important;
    }
  }
  .chart {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
  }
  @media(max-width: 1280px){
    .cefr-pic {
      margin-top: 20px;
      width: 100%;
      height: 550px;
      border-radius: 10px;
      background: url('../assets/image/LLJP_JLPT_banner_bg.png');
      background-size: cover;
      background-position: center;
      img {
        display: none;
      }
    }
    .cefr-content {
      p {
        padding: 0 20px 0 0;
      }
      width: 100% !important;
    }
    .active {
      background-color: #6c757d;
      color: #fff;
    }
    .select-box {
      border: 0px solid black !important;
      .maintitle {
        height: 10vh;
        h2 {
          position: relative;
          left: 0;
          text-align: center;
        }
      }
    }
  }
</style>
