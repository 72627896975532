<template>
  <div>
    <div class="container my-3" style="min-height:50vh">
      <div class="row">
        <div class="col-12">
          <div class="d-flex title-box">
            <div class="title" :class="{'active': type==='daily'}"  @click="goPage('daily')">{{ $t('Home.Daily_English') }}</div>
            <div class="title" :class="{'active' : type==='vocabulary'}"  @click="goPage('vocabulary')">{{ $t('Personal.Collection.Vocabulary') }}</div>
            <div class="title" :class="{'active': type==='lesson'}" @click="goPage('lesson')">{{ $t('Personal.Collection.Lesson') }}</div>
          </div>
        </div>
      </div>
      <div class="row my-3" v-if="type==='daily'">
        <div class="col-12" v-for="(item, index) in collections" :key="index">
          <div class="title d-flex justify-content-around align-self-center" >
            <div style="width:50%; text-align: left;">
              <h5 class="date">{{moment(item.json.publish_at).format('YYYY/MM/DD') }}
                <span class="badge badge-danger mx-1">{{item.json.category.name}}</span>
              </h5>
            </div>
            <div style="width:50%; text-align: right;">
              <span  @click="removeAlert(apiType,item.data.id)" class="pointer p-2">
                <img class="pointer" src="../assets/image/pic/btn_function_mark_on.png" alt="">
                <span class="rwd-hide">{{ $t('Home.Keep') }}</span>
              </span>
            </div>
          </div>
          <div class="daily-box" style="min-height:100px !important">
            <div>
              <h4 class="d-flex align-items-center flex-wrap">
                <span class="pointer pr-2" @click="playAudio(item.id, 'daily-sentences')">
                  <img style="width:30px" src="../assets/image/icon&btn/icon_have_btn_function/btn_function_play_voice.png" alt="">
                </span>
                <div class="rwd-font-size px-1 sentence" v-for="(el, idx) in splitSentence(item.json)" :key="idx">
                  <span v-if="item.json.dict" :class="{'dict': item.json.dict.find((i=>{ return i.key.toLowerCase() === el.toLowerCase()}))}" @click="setVocabulary(el,item.json.dict)">
                    <span v-html="forceToJap(el)"></span>
                  </span>
                  <span v-else>
                    <span v-html="forceToJap(el)"></span>
                  </span>
                  <div class="sentence-box" v-if="vocabularyData && vocabularyData.key.toLowerCase() === el.toLowerCase()">
                    <div class="sentence">
                      <font-awesome-icon class="pointer close" @click="closeDict" size="lg" :icon="['fa', 'times']"  style="color:rgba(255, 0, 0, .6);float: right;"/>
                      <h5 class="sentence-title">
                        <span v-html="forceToJap(vocabularyData.key)"></span>
                        <span class="pointer" @click="playVocabulary(el)">
                          <img style="width:30px" src="../assets/image/icon&btn/icon_have_btn_function/btn_function_play_voice.png" alt="">
                        </span>
                      </h5>
                      <p class="sentence-phon">
                        <span v-html="forceToJap(vocabularyData.phon)"></span>
                      </p>
                      <p class="sentence-explain">
                        <span v-html="forceToJap(vocabularyData.expl)"></span>
                      </p>
                    </div>
                    <audio v-if="vocabularyData" :id="`vocabulary${el}`" :ref="`vocabulary`">
                      <source :src="`https://api.liveabc.com/v1/livelibrary/Dict/Word/${vocabularyData.key}/audio?lang=ja`" type="audio/mp3">
                    </audio>
                  </div>
                </div>
              </h4>
              <h4 class="my-auto rwd-font-size px-1">{{item.json.local}}</h4>
            </div>
          </div>
          <div v-if="item.json.audio_url">
            <audio :id="`daily-sentences${item.id}`">
              <source :src="item.json.audio_url" type="audio/mp3">
            </audio>
          </div>
          <hr style='background-color:black; height:1px; border:none;'>
        </div>
        <audio v-if="vocabularyData" :id="`vocabulary${el}`" :ref="`vocabulary`">
          <source :src="`https://api.liveabc.com/v1/livelibrary/Dict/Word/${vocabularyData.key}/audio?lang=ja`" type="audio/mp3">
        </audio>
      </div>
      <div class="row my-3" v-if="type==='vocabulary'">
        <div class="container">
          <div class="row">
            <div class="col">
              <div v-for="(item, index) in collections" :key="index" class="my-3">
                <div :key="index" class="row align-items-center content-box" style="min-height:100px">
                  <div class="col-md-1">
                    <div class="my-auto">
                      <img @click="removeAlert(apiType,item.json.params.courseId,item)" class="pointer" src="../assets/image/pic/btn_function_mark_on.png" alt="">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="my-auto mx-2 d-flex pointer" @click="playAudio(item.id, 'word')">
                      <p class="p-0 my-1 main-title" v-html="forceToJap(item.name)"></p>
                      <img class="my-auto mx-2 pointer" src="../assets/image/pic/icon_voice.png" alt="">
                    </div>
                  </div>
                  <div class="col-md-2 d-flex">
                    <div class="my-auto mx-2 pointer">
                      <p class="p-0 my-1">{{item.json.vocabulary.pronouncing}}</p>
                    </div>
                  </div>
                  <div class="col-md-5 d-flex">
                    <div class="my-auto mx-2">
                      <p class="p-0 my-1">{{item.json.vocabulary.partOfSpeech}} {{item.json.vocabulary.name_i18N[0].value}}</p>
                    </div>
                  </div>
                  <div class="col-md-2" style="text-align:right">
                    <button v-if="item.released === true" type="button" @click="goLesson(item)" class="btn btn-danger">來源課程</button>
                    <button v-else type="button" disabled class="btn btn-danger">來源課程</button>
                  </div>
                </div>
                <div v-if="item.json.vocabulary.audio">
                  <audio :id="`word${item.id}`">
                    <source :src="item.json.vocabulary.audio" type="audio/mp3">
                  </audio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3" v-if="type==='lesson'">
        <div v-for="(item, index) in collections" :key="index" class="col-12" >
          <div :key="index" class="row align-items-center my-2">
            <div class="col-md-6 d-flex align-content-center m-0 p-0" style="height:80px;">
              <div class="content-box">
                <img @click="removeAlert(apiType,item.data.id)" class="pointer my-3" src="../assets/image/pic/btn_function_mark_on.png" alt="">
              </div>
              <div class="picture image-box pointer ml-2" style="width:100%;" >
                <img :src="item.data.banner" alt="">
                <h5 class="p-0 my-0">
                  {{item.data.name}}
                </h5>
              </div>
            </div>
            <div class="col-md-6 d-flex content-box my-2" style="height:80px">
              <div class="my-auto mr-2">
                <span class="badge badge-danger">{{item.data.cefr}}</span>
              </div>
              <div class="my-auto" style="width:75%">
                <p class="p-0 my-0" v-html="forceToJap(item.data.name_en)"></p>
              </div>
              <div class="my-auto">
                <button type="button" v-if="item.released=== true" @click="goLesson(item)" class="btn btn-danger">進入課程</button>
                <button type="button" v-else disabled class="btn btn-danger">進入課程</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center" style="height:200px"  v-if="collections">
        <div class="col" v-if="collections.length===0">
          <h4 class="my-auto" style="color:#8b8b8b">您還沒有收藏紀錄</h4>
        </div>
      </div>
    </div>
    <div v-if="collections">
      <pagination v-if="pageCount>1 && collections.length>0" class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import Player from 'live-player/dist/Player'
import LivePlayer from 'live-player'
import PopUp from '@/components/PopUp.vue'
import Pagination from '@/components/Pagination.vue'
import api from '../store/api'
export default {
  name: 'Collections',
  data () {
    return {
      type: 'daily',
      collections: null,
      vocabularyData: null,
      dailyNews: null,
      toggle: false,
      videoData: null,
      pageIndex: 1,
      pageCount: 0
    }
  },
  components: {
    LivePlayer,
    Player,
    PopUp,
    Pagination
  },
  computed: {
    apiType () {
      switch (this.type) {
        case 'lesson':
          return 'course'
        case 'vocabulary':
          return 'word'
        case 'daily':
          return 'daily_sentence'
      }
      return null
    }
  },
  mounted () {
    this.getCollections(this.apiType)
  },
  watch: {
    apiType (val) {
      this.collections = null
      if (val === 'daily_news') {
        this.getNews()
      }
      this.getCollections(val)
    }
  },
  methods: {
    goPage (el) {
      this.pageIndex = 1
      this.type = el
    },
    goLesson (el) {
      if (this.type === 'vocabulary') {
        this.$router.push({ name: 'CourseLesson', params: { lesson: el.json.params.lesson, courseId: el.json.params.courseId } })
      }else {
        this.$router.push({ name: 'CourseLesson', params: { lesson: el.data.id, courseId: el.json.view_id } })
      }
    },
    async getCollections (type) {
      try {
        const { data } = await api.get(`collections/${type}?page=${this.pageIndex}&pageSize=10`)
        this.collections = data.data
        this.pageCount = data.pageCount
      } catch (error) {
        console.error(error.response.data)
      }
    },
    forceToJap (sent, hidePinyin = false) {
      const split = sent.split(/{(.+?)}/)
      let newSent = ''
      for (const i in split) {
        if (split[i].indexOf('=') > -1) {
          if (!hidePinyin) {
            newSent += '<ruby>' + split[i].split(/=/)[0] + '<rt>' + split[i].split(/=/)[1] + '</rt></ruby>'
          } else {
            newSent += split[i].split(/=/)[0]
          }
        } else {
          newSent += split[i]
        }
      }
      return newSent
    },
    removeAlert (type, id, el) {
      let self = this
      self.$swal({
        title: '確認刪除?',
        showCancelButton: true,
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        closeOnConfirm: false
      }).then(function (isConfirm) {
        if (isConfirm.value === true) {
          self.removeCollections(type, id, el)
        }
      })
    },
    async removeCollections (type, id, el) {
      if (type === 'word') {
        try {
          const config = {
            data: {
              word: el.name
            }
          }
          await api.delete(`collections/${type}/${id}`, config)
          this.getCollections(this.apiType)
        } catch (error) {
          // console.error(error.response.data)
        }
      } else {
        try {
          await api.delete(`collections/${type}/${id}`)
          this.getCollections(this.apiType)
        } catch (error) {
          console.error(error.response.data)
        }
      }
    },
    // playAudio (id, type) {
    //   this.isPlay = id
    //   this.playAudio(id, type)
    // },
    playAudio (id, type) {
      let audio = document.getElementById(`${type}${id}`)
      this.pauseAll()
      audio.load()
      audio.play()
    },
    pauseAll () {
      let audios = document.getElementsByTagName('audio')
      const self = this
      const allAudios = []
      allAudios.forEach.call(audios, function (i) {
        i.currentTime = 0
        i !== self && i.pause()
      })
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getCollections(this.apiType)
      this.pauseAll()
    },
    close (close) {
      this.toggle = close
      this.videoData = null
    },
    openVideo (el) {
      this.videoData = el
      this.toggle = true
    },
    playVocabulary (el) {
      let audio = document.getElementById(`vocabulary${el}`)
      this.pauseAll()
      audio.load()
      audio.play()
    },
    splitSentence (el) {
      let sent = el.sent
      let sentArray = []
      for (let i = 0; i < el.dict.length; i++) {
        const s = sent.substring(el.dict[i].char_index, el.dict[i].char_index+el.dict[i].char_length)
        sentArray.push(s)
      }
      for (let i = 0; i < sentArray.length; i++) {
        sent = sent.replace(sentArray[i], `~${sentArray[i]}~`)
      }
      return sent.split(/[~*~]/)
    },
    setVocabulary (val,dict) {
      for (let i in dict) {
        if (dict[i].key.toLowerCase() === val.toLowerCase()) {
          this.vocabularyData = dict[i]
        }
      }
    },
    closeDict () {
      this.vocabularyData = null
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    height: 300px;
    background-image: url('../assets/image/pic/pic_blur_bg.jpg');
    // padding: 100px;
    // width: 100vw;
  }
  .title-box {
    margin-bottom: 20px;
    border-top: #aaa solid 1px;
    border-bottom: #aaa solid 1px;
    padding: 10px 0;
    .title {
      color: #aaa;
      margin: 0 50px 0 0;
      cursor: pointer;
    }
    .active {
      color: black;
    }
  }
  .picture {
    max-height: 80px;
    min-height: 80px;
    position:relative;
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition:all 0.2s ease;
    }
    h5 {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .content-box {
    height: 100%;
    background-color: rgb(230, 230, 230);
    text-align: left;
    padding: 8px;
  }
  table {
    text-align: left;
    border-collapse: collapse;
    width: 100%;
    min-width: 500px;
  }
  td {
    background-color: rgb(230, 230, 230);
    border: 4px solid #fff;
    text-align: left;
    padding: 8px;
  }
  .main-title {
    font-size:1.2rem;
    font-weight: bolder;
  }
  .daily-sentence {
    // text-align: left;
    h5 {
      font-weight: bold;
    }
  }
  .swal2-icon.swal2-warning {
    border-color: red !important;
    color: red !important;
  }

  .daily-box {
    // padding: 20px;
    h4 {
      line-height: 2rem;
      font-weight: 600;
    }
    text-align: left;
  }
  .date {
    font-size: 1rem;
  }
  .dict {
    color: rgb(255, 0, 0);
    cursor: pointer;
    text-decoration: underline;
  }
  .sentence {
    position: relative;
    .sentence-box {
      overflow: auto;
      box-shadow: 3px 3px 5px #afafaf;
      font-size: 1rem;
      padding: 5px 10px;
      width: 250px;
      position: absolute;
      max-height: 200px;
      border: 1px solid red;
      background-color: #fff;
      z-index: 40;
      .sentence {
        .sentence-title {
          color: red;
          line-height: 2.5rem;
          font-weight: 600;
          margin: 0;
        }
        .sentence-phon {
          font-size: .5rem;
          line-height: 1rem;
          margin: 0;
        }
        .sentence-explain {
          font-size: 1rem;
          font-weight: 600;
          line-height: 2rem;
          margin: 0;
        }
      }
    }
  }
  @media screen and (max-width: 450px) {
	.sentence-box {
    position: relative !important;
    width:80vw !important;
  }
}
</style>
